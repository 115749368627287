'use client';

import {config} from '@/config';
import {LoaderContext} from '@/context/LoaderContext';
import * as userSessionRepo from '@/repositories/user-session-repo';
import pkceChallenge, {PKCEResponse} from '@/services/pkce_challenge';
import {useRouter} from 'next/navigation';
import {useContext, useEffect} from 'react';

export default function Home() {
  const router = useRouter();
  const loader = useContext(LoaderContext);

  useEffect(() => {
    loader.showLoader('Logging in...');

    if (userSessionRepo.isLoggedIn()) {
      router.push('/');
    }

    // TODO: Show a loader while this is working.

    // Retrieve PKCE challenge and verifier and trade for the authorization code.
    pkceChallenge().then(
      (pkce: PKCEResponse) =>
        new Promise<void>(resolve => {
          const state = crypto.randomUUID();
          localStorage.setItem(
            userSessionRepo.UserSessionLocalStorageItem.CODE_CHALLENGE,
            pkce.code_challenge
          );
          localStorage.setItem(
            userSessionRepo.UserSessionLocalStorageItem.CODE_VERIFIER,
            pkce.code_verifier
          );
          localStorage.setItem(
            userSessionRepo.UserSessionLocalStorageItem.COGNITO_STATE,
            state
          );

          // Wait a bit for localStorage to commit these new values, then begin authorizing with Cognito.
          setTimeout(async () => {
            router.push(
              `${
                config.cognitoDomain
              }/oauth2/authorize?response_type=code&client_id=${await config.cognitoClientId}&redirect_uri=${config.appUrl()}/login/oauth2/code/cognito/&state=${state}&code_challenge_method=S256&code_challenge=${
                pkce.code_challenge
              }`
            );
            resolve();
          }, 250);
        })
    );
  });

  return <></>;
}
